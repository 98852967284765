
#waitingScreenWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  video {
    pointer-events: none;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none;
  }

  .messageWrapper {
    position: absolute;
    top: 0;
    background-color: rgba(22, 22, 22, 0.746);
    color: white;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    width: 100vw;
  }
}