* {
  box-sizing: border-box;
}

body {
  background-color: rgb(34, 38, 41);
}

.sessionInitiatorModalWrapper {
  width: 100%;
  background-color: white;
  position: relative;
  height: 100%;

  .joinerScreen {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 15px;

      .webJoinWrapper {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;

          .joiningHeader {
              padding-top: 15px;

              h4 {
                  margin-top: 25px;
                  color: black;
              }
          }

          border-left: 1px solid rgb(212, 212, 212);
          padding: 0 10px;
          .joiningText {
              margin-top: 20px;
              margin-bottom: 20px;
          }
      }

      .callJoinWrapper {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          padding: 0 10px;
      }

      .joiningHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .joinerLogo {
              width: 70px;
          }
          h4 {
            color: black!important;
        }
      }

      .joiningText {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;

          p {
              margin-bottom: 5px;
              margin-top: 5px;
              font-size: 0.8rem;
          }

          .highlightedText {
              font-size: 0.95rem;
              font-weight: 600;
              padding: 5px 25px;
              background-color: rgb(226, 226, 226);

              &.shareLinkText {
                  font-size: 0.7rem;
                  text-align: center;
              }
          }

      }
  }

  .sessionHelper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 28px;
      background-color: rgb(54, 54, 54);
      color: white !important;

      .leftPart {
          .patientsJoined {
              font-size: 0.8rem;
          }
      }

      .rightPart {
          .sessionStartBtn {
              border: none;
              height: 60px;
              padding: 0;
              border-radius: 60px;
              font-size: 0.6rem;
              font-weight: 600;
              box-shadow: 0px 0px 20px rgba(1, 148, 33, 0.541);
              background-color: rgb(35, 180, 35);
              cursor: pointer;
              color: white;
              transition: 0.3s;
              margin-right: 20px;
              width: 60px;

              &:hover {
                  box-shadow: 0px 0px 20px rgba(1, 148, 33, 0.192);
                  background-color: rgb(4, 148, 4);
              }
          }
      }
  }

  .joiningScreen {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 60px;
      background-color: rgb(54, 54, 54);
      color: white !important;

      .leftPart {
          text-align: end;
          // background-color: green;
          margin-right: -75px;
      }

      .rightPart {
          text-align: start;
          margin-left: -75px;
          // background-color: green;
      }
  }
}