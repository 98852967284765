.callPromptWrapper {
  height: 100%;

  .iconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      height: 120px;
      width: 120px;
      border-radius: 120px;
      background-color: white;
      margin-top: -80px;
      border: 2px solid black;
      font-size: 2.3rem;

      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
    }
  }

  .userPromptView {
    position: relative;
    height: 100%;
    width: 100%;

    .actionBar {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgb(35, 187, 32);
      height: 60px;
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      cursor: pointer;

      &:hover {
        background-color: rgb(41, 226, 38);
      }
    }

    .headerText {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      font-size: 1.1rem;
    }

    .inputWrapper {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 380px;
        height: 40px;
        flex-direction: column;
        background-color: rgb(219, 219, 219);
        border: 2px solid rgb(219, 219, 219);
        font-size: 1rem;
        border-radius: 40px;
        padding-left: 30px;
        outline: none;

        &:hover {
          background-color: rgba(219, 219, 219, 0.455);
          border: 2px solid rgba(219, 219, 219, 0.455);
        }

        &:active,
        &:focus {
          background-color: rgb(246, 246, 246);
          border: 2px solid rgb(48, 116, 253);
        }
      }
    }

    .errorText {
      margin-top: -71px !important;
      width: 60%;
      margin: auto;
      font-size: 0.8rem;
      color: red;
      height: 20px;
    }
  }
  .loaderView {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .errorPromptView {
    position: relative;
    height: 100%;
    width: 100%;
    .icon {
      color: red;
    }

    .actionBar {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgb(35, 187, 32);
      height: 60px;
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      cursor: pointer;

      &:hover {
        background-color: rgb(41, 226, 38);
      }
    }

    .headerText {
      width: 100%;
      text-align: center;
      color: red;
      margin-top: 10%;
      font-size: 1.4rem;
    }

    .inputWrapper {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 380px;
        height: 40px;
        flex-direction: column;
        background-color: rgb(219, 219, 219);
        border: 2px solid rgb(219, 219, 219);
        font-size: 1rem;
        border-radius: 40px;
        padding-left: 30px;
        outline: none;

        &:hover {
          background-color: rgba(219, 219, 219, 0.455);
          border: 2px solid rgba(219, 219, 219, 0.455);
        }

        &:active,
        &:focus {
          background-color: rgb(246, 246, 246);
          border: 2px solid rgb(48, 116, 253);
        }
      }
    }

    .errorText {
      margin-top: -71px !important;
      width: 60%;
      margin: auto;
      font-size: 0.8rem;
      color: red;
      height: 20px;
    }
  }
}