#postCallPageWrapper {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  #postCallPageInnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white !important;

    button {
      margin-top: 10px;
      cursor: pointer;
      transition: 0.6s;
      box-shadow: 5px 5px 25px rgba(255, 0, 0, 0.534);
      border: none;
      background-color: red;
      padding: 8px 45px;
      font-size: 0.8rem;
      font-weight: 600;
      color: white;
      &:hover {
        box-shadow: 5px 5px 25px rgba(255, 0, 0, 0.123);
      }
    }
  }
}