#web-call-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-size: cover;
  background-position-x: 50%;
  position: relative;
  background: rgb(20, 20, 20);
  background: linear-gradient(135deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 51%, rgba(19, 75, 87, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  .logoWrapper {
    z-index: 999;
    background-color: rgba(255, 255, 255, 0);
    width: 100vw;
    height: 200px;
    position: fixed;
    top: 0;
    right: 0;

    .logoInnerWrapper {
      padding: 10px 30px;

      .logoImg {
        height: 70px;
        border-radius: 60px;
      }
    }
  }

  .sessionInfoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    // height: 60px;
    width: 100vw;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    color: white;
  }

  #fullscreenRemoteVideo {
    width: 100%;
    height: 100%;
    // background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 51%, rgba(19,75,87,1) 100%);background-color: rgb(59, 59, 59);
    // position: absolute;
    // top: 0;
    // left: 0;
    z-index: 99999999999995959595;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  #floatingLocalVideo {
    width: 11vw;
    height: 11vw;

    @media only screen and (max-width: 950px) {
      width: 28vw;
      height: 28vw;
    }

    position: absolute;
    top: 7vh;
    right: 3vw;
    background-color: rgba(255, 255, 255, 0);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 3px solid white;
    // border-radius: 15rem;
    box-shadow: 0px 0px 30px rgba(230, 230, 230, 0.575);
    // border: 3px solid white;
    // border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0px 0px 10px rgba(29, 29, 29, 0.465);

    &:hover {
      width: 22vw;
      height: 22vw;

      @media only screen and (max-width: 950px) {
        width: 28vw;
        height: 28vw;
      }

      box-shadow: 0px 0px 30px rgb(230, 230, 230);
    }

    video {
      // border-radius: 15rem;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .gridView {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;

    &.screenShareView {
      background-color: rgba(255, 0, 0, 0);
      position: absolute;
      right: 0;
      overflow-y: scroll;
      min-height: 100vh;
      height: unset;
      top: 0;
      width: 200px;
      flex-direction: column;

      @media only screen and (max-width: 800px) {
        width: 0;
      }

      .videoWindow {
        margin-bottom: 0;
        width: 90%;

        @media only screen and (max-width: 950px) {
          width: 47vw;
        }

        border-radius: 2px;

        &.screenShareFull {
          position: fixed;
          left: 0;
          height: 100%;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100vw - 200px);

          @media only screen and (max-width: 800px) {
            width: 100vw;
          }

          flex-direction: column;
          background-color: rgba(255, 255, 255, 0);
          border: none;

          &:hover {
            box-shadow: none;
          }

          video {
            height: unset;
          }

          .userInfo {
            position: relative;
            border-radius: 0;

          }
        }
      }
    }

    .videoWindow {
      margin-bottom: 0;
      width: 32%;

      @media only screen and (max-width: 950px) {
        width: 47vw;
      }

      margin: 5px;
      cursor: pointer;
      background-color: black;
      border: 2px solid rgb(255, 255, 255);
      border-radius: 10px;
      position: relative;
      transition: 0.2s;

      &:hover {
        border: 2px solid rgb(20, 20, 20);
        box-shadow: 0px 0px 30px rgb(230, 230, 230);
      }

      &.screenShareFull {}

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .userInfo {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgb(255, 255, 255);
        color: rgb(10, 10, 10);
        padding: 3px 15px;
        text-align: center;
        width: 100%;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        @media only screen and (max-width: 950px) {
          // width: 43vw;
        }
      }
    }
  }

  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    left: 40px;
    background-color: rgb(19, 19, 19);
    color: #FFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 3px #999;
  }

  .my-float {
    margin-top: 22px;
  }

  #actions-sheet-wrapper {
    width: 100vw;
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    #actions-sheet {
      width: 500px;
      height: 90px;
      background-color: black;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.554);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 3rem;
      align-items: center;

      .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
        color: white;

        &.enabled {
          border-radius: 100px;
          background-color: rgb(32, 32, 32);
        }

        &.red {
          color: red;
          transform: rotate(135deg) !important;
        }
      }
    }
  }




  .fab-container {
    z-index: 100;
    bottom: 10px !important;
    position: fixed;
    margin: 1em;
    left: 10px !important;
    width: 100px;

    @media only screen and (max-width: 950px) {
      display: none;
    }

    button.fab-item {
      background-color: black !important;
    }
  }

}

#localMedia1 {
  video {
    // width: 100%;
    height: 200px;
    object-fit: fill;
  }
}

#largeScreenWapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;

  #largeScreen {
    width: 95%;
    height: 95%;
    background-color: black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      width: 90vw;
      height: 50vw;
      object-fit: contain;
    }

    #bottomBar {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 99999999999;
      width: 100%;
      height: 50px;
      background-color: white;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 60px;

      .close {
        color: red;
        cursor: pointer;
      }
    }
  }
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}